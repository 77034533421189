import { Params } from "@angular/router";
import { MemberGetRequestParams } from "@app/core/api/api/api";

export const mapToGetMembersRequestParamsFromGetMembersQueryParams = (queryParams: Params): MemberGetRequestParams => {
    let mappedQueryParams: MemberGetRequestParams = {
        pageSize: 10,
        pageNumber: 1,
    };

    if (queryParams?.["pageSize"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            pageSize: queryParams["pageSize"],
        };
    }

    if (queryParams?.["pageNumber"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            pageNumber: queryParams["pageNumber"],
        };
    }

    if (queryParams?.["query"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            searchPhrase: queryParams["query"],
        };
    }

    if (queryParams?.["memberStatuses"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            statuses: queryParams["memberStatuses"].split(","),
        };
    }

    if (queryParams?.["addressesStates"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            addressesStates: queryParams["addressesStates"].split(","),
        };
    }

    if (queryParams?.["homeLocations"]) {
        mappedQueryParams = {
            ...mappedQueryParams,
            homeLocations: queryParams["homeLocations"].split(","),
        };
    }

    if (queryParams?.["sortBy"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          sortByColumn: queryParams["sortBy"],
      };
  }

  if (queryParams?.["sortDirection"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          sortByDirection: queryParams["sortDirection"],
      };
  }

    return mappedQueryParams;
};
