import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { MEMBERS_FEATURE_KEY, MembersStateModel } from "./members.state.model";
import { MemberService } from "@app/core/api/index";
import { GetMembersAction } from "./members.actions";
import { catchError, EMPTY, tap } from "rxjs";
import { SetPaginationAction } from "@app/core/store/pagination";
import { getPaginationFromListResponse } from "@app/shared/utils/get-pagination-from-list-response";
import { RouterState } from "@ngxs/router-plugin";
import { mapToGetMembersRequestParamsFromGetMembersQueryParams } from "../../utils";
import { LoadHomeLocationsAction, LoadSelectedDictionariesAction } from "@app/core/store/dictionaries";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationsService } from "@app/core/services/notifications";

@State<MembersStateModel>({
    name: MEMBERS_FEATURE_KEY,
    defaults: {
        members: [],
        isLoading: false,
        error: null,
    },
})
@Injectable({ providedIn: "root" })
export class MembersState {
    constructor(private membersService: MemberService, private store: Store, private notificationsService: NotificationsService) {}

    @Action(GetMembersAction)
    getMembers(ctx: StateContext<MembersStateModel>) {
        ctx.patchState({ members: [], isLoading: true, error: null });

        const queryParams = this.store.selectSnapshot(RouterState.state)?.root?.queryParams || {};

        return this.membersService.memberGet(mapToGetMembersRequestParamsFromGetMembersQueryParams({ ...queryParams })).pipe(
            tap((members) => {
                ctx.patchState({ members: members.result, isLoading: false });
                ctx.dispatch(new SetPaginationAction({ ...getPaginationFromListResponse(members) }));
                ctx.dispatch(new LoadSelectedDictionariesAction(["MemberStates", "MemberStatuses"]));
                ctx.dispatch(new LoadHomeLocationsAction());
            }),
            catchError((error: HttpErrorResponse) => {
              this.notificationsService.error("An unexpected error occurred while fetching a list of members.");
              ctx.patchState({
                  isLoading: false,
                  error: error.statusText,
              });
              return EMPTY;
          }),
        );
    }
}
