import { MemberBaseData } from "@app/core/api/index";
import { MemberCompactData } from "../../../state/members/members.state.model";

export const mapToMemberCompactDataFromMemberBaseData = (response: MemberBaseData[]): MemberCompactData[] => {
    return response!.map(
        (memberBaseData: MemberBaseData): MemberCompactData => ({
            id: memberBaseData.memberId || "",
            barcode: memberBaseData.barcode || "",
            fullName: memberBaseData.fullName || "",
            email: memberBaseData.email || "",
            clubCode: memberBaseData.clubCode || "",
            clubName: memberBaseData.clubName || "",
            membershipType: memberBaseData.membershipType || "",
            state: memberBaseData.state || "",
            status: memberBaseData.status || "",
        }),
    );
};


