import { MemberBaseData } from "@app/core/api/index";
import { ITableItem } from "@app/shared/components/table/models";

export const MEMBERS_FEATURE_KEY = "members";

export interface MemberCompactData extends ITableItem {
    id: string;
    barcode: string;
    fullName: string;
    email: string;
    clubCode: string;
    clubName: string;
    membershipType: string;
    state: string;
    status: string;
}

export interface MembersStateModel {
    members: MemberBaseData[] | null;
    isLoading: boolean;
    error: string | null;
}
